import React from "react";

import "../styles/Project.scss";

const Project = (props) => {
  const {
    className,
    style,
    image,
    title,
    technologies,
    description
  } = props;

  return (
    <div className={`projectCard rounded-lg ${className ?? ""}`}
         style={{
           backgroundImage: `url("${image}")`,
           ...style
         }}>
      <div className="projectCardBody">
        <span className="projectTitle">{title}</span>
        <span className="projectTech">{technologies}</span>
        <span className="projectDescription">{description}</span>
      </div>
    </div>
  )
}

export default Project;