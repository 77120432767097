import React from "react";

const Skill = (props) => {
  const {
    img,
    title,
    description,
    className,
    style
  } = props;

  return (
    <div className={`flex flex-col items-center mt-8 px-6 ${className}`} style={{minWidth: 300, ...style}}>
      <img className="" src={img} />
      <h3 className="mt-5 text-center" style={{fontSize: 19, fontWeight: 400, color: "#1F2732"}}>
        {title}
      </h3>
      <p className="mt-3 text-center" style={{fontSize: 16, fontWeight: 300, color: "#7F7F7F"}}>
        {description}
      </p>
    </div>
  )
}

export default Skill;