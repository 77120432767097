import React from "react";

import "../styles/timeline.scss";

const Timeline = (props) => {
  const {
    data,
    className,
    style
  } = props;

  return (
    <div className={`timeline flex flex-col ${className ?? ""}`} style={{...style}}>
      {data.map((item, id) => (
        <React.Fragment key={item.title}>
          {/* desktop */}
          <div className="lg:flex hidden items-center justify-center my-4 mx-auto" style={{width: "80%"}}>
            <div className="flex-1 flex justify-end text-right mr-4">
              {id % 2 === 0 ? <div className="flex items-center justify-center">
                <span className="-mr-12" style={{color: item.color, fontSize: 200, fontWeight: 600}}>0{id + 1}</span>
                <span className="" style={{fontSize: 48, fontWeight: 500}} >{item.title}</span>
              </div> : <span className="text-gray-800" style={{lineHeight: "30px"}}>
                {item.description}
              </span>}
            </div>
            <div className="flex-1 flex justify-center mx-4">
              <img src={item.img} className="rounded-full border-gray-300" style={{borderWidth: 12}} />
            </div>
            <div className="flex-1 flex justify-start text-left ml-4" style={{lineHeight: "30px"}}>
              {id % 2 === 1 ? <div className="flex flex-row-reverse items-center justify-center">
                <span className="-ml-20" style={{color: item.color, fontSize: 200, fontWeight: 600}}>0{id + 1}</span>
                <span className="text-gray-900" style={{fontSize: 48, fontWeight: 500}} >{item.title}</span>
              </div> : <>
                {item.description}
              </>}
            </div>
          </div>
          {/* mobile */}
          <div className="lg:hidden flex flex-col items-center justify-center my-6 mx-auto" style={{width: "75%", maxWidth: 400}}>
            <div className="mobile-title w-full flex justify-between">
              <span className="text-left text-4xl" style={{fontWeight: 500, lineHeight: "44px", color: item.color}}>0{id + 1}</span>
              <span className="text-right text-4xl text-ray-800" style={{fontWeight: 500, lineHeight: "44px"}}>{item.title}</span>
            </div>
            <div className="my-5">
              <img src={item.img} className="rounded-full border-gray-300" style={{borderWidth: 12}} />
            </div>
            <div className="w-full flex items-center" style={{fontSize: 16, lineHeight: "30px"}}>
              <span className="text-center text-gray-900">{item.description}</span>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Timeline;