import * as React from "react";
import { Helmet } from 'react-helmet'
import Favicon from "../images/favicon.ico";

import {Hero, Header, Footer} from "../fragments/";
import Skill from "../components/Skill";
import Divider from "../components/Divider";
import Timeline from "../components/Timeline";
import ProjectCard from "../components/Project";

import TimelineIcon from "../images/timelineTest.jpg";
import ServiceIcon from "../images/service_icon.png";

import SkillMonitoring from "../images/skills/monitoring.png";
import SkillMobile from "../images/skills/mobile.png";
import SkillWeb from "../images/skills/website.png";
import SkillSeo from "../images/skills/seo.png";
import SkillHebergement from "../images/skills/hebergement.png";
import SkillMaintenance from "../images/skills/maintenance.png";

import ProjectAlterway from "../images/projects/alterway.png";
import ProjectGpgCrm from "../images/projects/gpg_crm.png";
import ProjectMilleparty from "../images/projects/milleparty.png";
import ProjectProjipac4 from "../images/projects/projipac4.png";
import ProjectJabi from "../images/projects/jabi.jpg";

import TimelineAudit from "../images/timeline/audit.png";
import TimelineDev from "../images/timeline/dev.png";
import TimelineFormation from "../images/timeline/formation.png";
import TimelineMaintenance from "../images/timeline/maintenance.png";

import "../styles/Home.css";

const IndexPage = () => {
  return (
    <main className="overflow-hidden home">
      <Helmet>
        <title>Wisoftify - Home</title>
        <link rel="icon" href={Favicon} />
      </Helmet>
      <Header />
      <Hero />
      <div className="bg-gray-100 pt-8 pb-6">
        <h2 className="text-center">Nos compétences à votre service</h2>
        <div className="flex items-start justify-center flex-wrap mt-4 mx-auto" style={{width: "60%"}}>
          <Skill img={SkillWeb}
                 title="Développement Web"
                 description="Nous utilisons les technologies les plus appropriés a votre projet afin de créer votre site, a votre image" 
                 style={{width: "33.33%"}}
          />
          <Skill img={SkillMobile}
                 title="Développement Mobile"
                 description="Que ce soit pour Android ou pour IOS, développons ensemble votre application de demain"
                 style={{width: "33.33%"}}
          />
          <Skill img={SkillMonitoring}
                 title="Monitoring"
                 description="L'utilisation d'outils avancé de monitoring nous permet de détécter toute panne de votre site afin d'y réagir le plus rapidement possible." 
                 style={{width: "33.33%"}}
          />
          <Skill img={SkillSeo}
                 title="Référencement"
                 description="Nous optimisons ensemble le référencement de votre site grâce a nos connaissances des fonctionnements des moteurs de recherches tel que Google" 
                 style={{width: "33.33%"}}
          />
          <Skill img={SkillHebergement}
                 title="Hébergement"
                 description="Nous vous aidons dans chaque phase de votre projet, y compris l'installation de votre application sur les serveurs apropriés"
                 style={{width: "33.33%"}}
          />
          <Skill img={SkillMaintenance}
                 title="Maintenance"
                 description="Une fois notre travail terminé, vous pourrez faire appel à nous pour la maintenance de votre application. "
                 style={{width: "33.33%"}}
          />
        </div>
        <Divider height={60} className="my-6" />



        <h2 className="text-center my-4">Votre projet en quelques étapes</h2>
        <Timeline data={[{
            title: "Audit",
            img: TimelineAudit,
            description: "Nous passons du temps avec vous afin de détailler au maximum ce a quoi ressemblera votre projet. Cette étape de préparation très importante nous permet de produire un code de qualité, pérenne dans la durée et avec le minimum de dette technique possible.",
            color: "#4979ED"
          }, {
            title: "Développement",
            img: TimelineDev,
            description: "Nous développons votre application en suivant à la lettre les points abordés lors de l'audit. Avec des réunions et des mails régulier, vous verrez en temps réel l'avancée des travaux de développement. ",
            color: "#4979ED"
          }, {
            title: "Formation",
            img: TimelineFormation,
            description: "Car un outil sur mesure nécessite une formation sur mesure. Nous vous aidons a vous lancer sur votre produit flambant neuf pour vous permettre de l'utiliser au mieux en toute autonomie.",
            color: "#4979ED"
          }, {
            title: "Maintenance",
            img: TimelineMaintenance,
            description: "Pour que votre application continue a vivre, nous restons disponible pour effectuer des mises à jour ou toute action de maintenance pour que votre projet continue a vivre au fil des ans.",
            color: "#4979ED"
          }]} />
        <Divider height={60} className="my-6" />



        <h2 className="text-center my-4">Nos réalisations</h2>
        <div className="hidden lg:flex flex-col items-stretch mx-auto home_project_container">
          <div style={{flex: 2, gap: 10}} className="flex flex-row flex-wrap items-stretch">
            <div style={{flex: 1}}>
              <ProjectCard image={ProjectAlterway}
                          style={{flex: 1}}
                          title="Alterway"
                          technologies="Next.js / React / Wordpress"
                          description="Création d'un site web vitrine sur le modèle de la JAMSTACK. La simplicité d'utilisation de Wordpress combiné aux hautes performances de Next.js en fait un cas d'école des normes d'éco-conception dans le monde informatique."
              />
            </div>
            <div style={{flex: 2, gap: 10}} className="flex flex-col items-stretch flex-wrap">
              <ProjectCard image={ProjectProjipac4}
                  style={{flex: 1}}
                  title="Projipac 4"
                  technologies="React / Sails.js"
                  description="Développé pour le groupe Atlantic, Cette application web combine un utilitaire de dimensionnement de pompe à chaleur pour les particuliers ainsi qu'un système de gestion des stocks a l'échelle internationale."
              />
              <div className="flex-1 flex flex-row fix-row items-stretch" style={{gap: 10}}>
                <ProjectCard image={ProjectMilleparty}
                    style={{flex: 1}}
                    title="Milleparty"
                    technologies="Wordpress / React"
                    description="Car aujourd'hui la digitalisation atteint tous les domaines d'activité. Milleparty est la plateforme qui vous permettra d'organiser le plus bel anniversaire pour vos enfants."
                />
                <ProjectCard image={ProjectGpgCrm}
                  style={{flex: 1}}
                  title="CRM GPG"
                  technologies="Ruby on rails"
                  description="Développé pour le compte de GPG Granite, ce CRM sur mesure leur permet aujourd'hui de suivre tout le parcours d'un client et d'aider les commerciaux dans leur travail de tous les jours."
                />
              </div>
            </div>
          </div>
          <ProjectCard image={ProjectJabi}
            style={{flex: 1}}
            title="JABI Autonomie"
            technologies="React Native / React / Firebase"
            description="Créé dans le but de détecter et ralentir les troubles neuro-cognitif chez les séniors. Jabi combine une application mobile pour les personnes agées possédant des jeux stimulants ainsi que des fonctionnalités simplifié de messagerie avec un tableau de bord pour simplifier la gestion d'un établissement senior aux personnels soignants."
          />
        </div>
        <div className="flex lg:hidden flex-col mx-auto" style={{width: "90%", gap: 10}}>
          <ProjectCard 
            image={ProjectAlterway}
            style={{flex: 1}}
            title="Alterway"
            technologies="Next.js / React / Wordpress"
            description="Création d'un site web vitrine sur le modèle de la JAMSTACK. La simplicité d'utilisation de Wordpress combiné aux hautes performances de Next.js en fait un cas d'école des normes d'éco-conception dans le monde informatique."
          />
          <ProjectCard 
            image={ProjectProjipac4}
            style={{flex: 1}}
            title="Projipac 4"
            technologies="React / Sails.js"
            description="Développé pour le groupe Atlantic, Cette application web combine un utilitaire de dimensionnement de pompe à chaleur pour les particuliers ainsi qu'un système de gestion des stocks a l'échelle internationale."
          />
          <ProjectCard
            image={ProjectMilleparty}
            style={{flex: 1}}
            title="Milleparty"
            technologies="Wordpress / React"
            description="Car aujourd'hui la digitalisation atteint tous les domaines d'activité. Milleparty est la plateforme qui vous permettra d'organiser le plus bel anniversaire pour vos enfants."
          />
          <ProjectCard 
            image={ProjectGpgCrm}
            style={{flex: 1}}
            title="CRM GPG"
            technologies="Ruby on rails"
            description="Développé pour le compte de GPG Granite, ce CRM sur mesure leur permet aujourd'hui de suivre tout le parcours d'un client et d'aider les commerciaux dans leur travail de tous les jours."
          />
          <ProjectCard
            image={ProjectJabi}
            style={{flex: 1}}
            title="JABI Autonomie"
            technologies="React Native / React / Firebase"
            description="Créé dans le but de détecter et ralentir les troubles neuro-cognitif chez les séniors. Jabi combine une application mobile, des jeux stimulants ainsi que des fonctionnalités simplifié de messagerie avec un tableau de bord simplifiant la gestion d'établissement sénior."
          />
        </div>
        <Divider height={60} className="my-6" />
      </div>
      <Footer />
    </main>
  )
}

export default IndexPage
